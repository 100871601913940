<template>
  <div class="mobility-booking">
    <div>
      <div class="mobility-days-of-week">
        <span v-for="date in bookingDates" :key="date.iso" :data-status="days_of_week[date.day]" @click="selectDay(date.day)">{{ date.letter }}</span>
      </div>

      <div class="mobility-input-select">
        <el-select v-model="direction" placeholder="Direction">
          <el-option value="go" label="Going to the office"/>
          <el-option value="back" label="Going home"/>
          <el-option value="both" label="Both ways"/>
        </el-select>

        <!-- To the office -->
        <div v-if="direction == 'both' || direction == 'go'">
          <span style="display: block; margin-top: 10px">To office</span>
          <div style="display: flex;">
            <el-select v-model="stop" placeholder="Stop">
              <el-option value="1" label="D’Olier Street"/>
              <el-option value="2" label="St. Stephen Green East"/>
              <el-option value="3" label="Leeson Street Bridge"/>
              <el-option value="4" label="Morehampton Road"/>
              <el-option value="5" label="Opposite Donnybrook Fire Station"/>
              <el-option value="6" label="Donnybrook Church"/>
              <el-option value="7" label="Stillorgan Road"/>
              <el-option value="8" label="Brewery Road"/>
              <el-option value="9" label="Central Park"/>
            </el-select>

            <el-select style="margin-left: 10px; width: 120px" v-model="time" placeholder="Time">
              <el-option value="1" label="07:15"/>
              <el-option value="2" label="08:15"/>
              <el-option value="3" label="08:35"/>
            </el-select>
          </div>
        </div>

        <div v-if="direction == 'both' || direction == 'back'">
          <span style="display: block; margin-top: 10px">From office</span>
          <div style="display: flex;">
            <el-select v-model="stop" placeholder="Stop">
              <el-option value="9" label="Central Park"/>
              <el-option value="8" label="Brewery Road"/>
              <el-option value="7" label="Stillorgan Road"/>
              <el-option value="6" label="Donnybrook Church"/>
              <el-option value="5" label="Opposite Donnybrook Fire Station"/>
              <el-option value="4" label="Morehampton Road"/>
              <el-option value="3" label="Leeson Street Bridge"/>
              <el-option value="2" label="St. Stephen Green East"/>
              <el-option value="1" label="D’Olier Street"/>
            </el-select>

            <el-select style="margin-left: 10px; width: 120px" v-model="time" placeholder="Time">
              <el-option value="1" label="16:15"/>
              <el-option value="2" label="17:15"/>
              <el-option value="3" label="17:35"/>
            </el-select>
          </div>
        </div>
      </div>

      <el-button @click="book" :disabled="!this.direction || !this.stop" type="primary" class="secondary-color w100" style="margin-top: 10px;">Book now</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bookingDates: { type: Array, default: () => ([]) }
  },

  data: () => ({
    days_of_week: {
      monday: "inactive",
      tuesday: "inactive",
      wednesday: "inactive",
      thursday: "inactive",
      friday: "inactive",
      saturday: "disabled",
      sunday: "disabled"
    },
    direction: 'go',
    stop: null,
    time: null
  }),

  methods: {
    selectDay(day) {
      if (!this.days_of_week[day] || this.days_of_week[day] == "disabled") {
        return;
      }

      // Switch day of week
      this.days_of_week[day] == "active" ? this.days_of_week[day] = "inactive" : this.days_of_week[day] = "active";
    },

    book() {
      if (!this.direction || !this.stop) {
        return;
      } 

      this.$emit('onBook', {
        days_of_week: {
          monday: this.days_of_week.monday == "active",
          tuesday: this.days_of_week.tuesday == "active",
          wednesday: this.days_of_week.wednesday == "active",
          thursday: this.days_of_week.thursday == "active",
          friday: this.days_of_week.friday == "active",
          saturday: this.days_of_week.saturday == "active",
          sunday: this.days_of_week.sunday == "active"
        },
        direction: this.direction,
        stop: this.stop,
        time: this.time
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobility-booking {
    width: 100%;
    display: flex;
    justify-content: center;

    & > div {
      max-width: 400px;
    }

    .mobility-days-of-week {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > span {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        font-size: 19px;
        padding-top: 7px;
        font-family: 'Rubik', sans-serif !important;
        font-weight: 600;

        &[data-status="inactive"] {
          background-color: #FFF;
          border: 2px solid #4363E2;
          color: #4363E2;
        }
        
        &[data-status="active"] {
          background-color: #4363E2;
          border: 2px solid #4363E2;
          color: white;
        }

        &[data-status="disabled"] {
          background-color: #CFCFCF;
          border: 2px solid #CFCFCF;
          color: white;
        }
      }
    }

    .mobility-input-select {
      margin-top: 12px;
    }
  }
</style>